<template>
  <div class="about p-4">
    <h1 class="text-xl m-2">La roulette du destin</h1>
    <div class="p-1 text-justify">
      <p>
        La roulette du destin est un jeu inventé à l'occasion des baptêmes annuels de notre équipe.
        Il est rapidement devenu un des incontournables de nos 3ème mi-temps avant de rendre l'âme et de laisser un grand vide dans nos diamnches.
        Nous avons donc décidé de le ramener à la vie au coin d'une table, une bière à la main.
      </p>
      <br>
      <p>
        Le principe est simple, le joueur lance la roue et se voit attribuer une boisson.
        Il relance ensuite deux fois la roue afin de savoir dans quelle position et à quel endroit il devra la boire.
      </p>
      <br>
      <p>
        Bon amusement,
      </p>
      <p>Les Moines</p>
    </div>
  </div>
</template>
